<template>
  <div class="hold-transition login-page" style="height: 100%">
    <div
        class="wrapper"
        style="height: 100%; padding-top: 60px; background: #d2d6de"
    >
      <div class="login-box">
        <div class="login-logo">
          <a href="javascript:;">登录页面</a>
        </div>
        <div class="login-box-body">
          <p class="login-box-msg">登录你的账号</p>
          <div class="form-group has-feedback">
            <span class="fa fa-user form-control-feedback"></span>
            <input-view
                v-model="account"
                class="form-control"
                placeholder="账号"
            />
          </div>
          <div class="form-group has-feedback">
            <input-view
                v-model="password"
                class="form-control"
                autocomplete="new-password"
                type="password"
                placeholder="密码"
            />
            <span class="fa fa-lock form-control-feedback"></span>
          </div>
          <div class="row">
            <div class="col-xs-8">
              <div class="checkbox pull-left">
                <label>
                  <input v-model="rememberMe" type="checkbox" /> 记住我
                </label>
              </div>
            </div>
            <div class="col-xs-4">
              <input
                  class="btn btn-primary btn-block btn-flat"
                  type="submit"
                  value="登录"
                  v-on:click="sign"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StorageUtils from '../utils/StorageUtils'
import StringUtils from "@/utils/StringUtils";
import SHA1 from "crypto-js/sha1";
export default {
  data() {
    return {
      account:'',
      password:'',
      rememberMe:0
    }
  },

  mounted() {
    StorageUtils.localStorageSet('token',null)
  },

  methods: {
    sign: function () {
      if(StringUtils.isNullOrEmpty(this.account)){
        $Message.warning("请输入账号或手机号");
        return;
      }
      if(StringUtils.isNullOrEmpty(this.password)){
        $Message.warning("请输入密码");
        return;
      }

      this.$Http("/login",{account:this.account,password:SHA1(this.password).toString()},"POST",true).then((result)=>{
        let data=result.data.data;
        StorageUtils.localStorageSet('token', data.token)
        StorageUtils.localStorageSet('expiresTime', data.expireTime);
        this.$Store.storeSet('user', data.user);
        let redirect = this.$route.query.redirect
        if (redirect != null) {
          if (redirect == location.hostname) {
            this.$router.go(-1)
          } else {
            this.$router.push({ path: redirect })
          }
        } else {
          this.$router.push({ path: '/' })
        }
      });
    }
  }
}
</script>
