<template>
  <div :id='id' class='checkbox' data-toggle='tooltip' data-placement='bottom' data-trigger='manual'>
    <label v-for="item in checkboxArray" class="check-item">
      <input
        type="checkbox"
        :value="item.key"
        @click="changeEvent(item)"
        v-if="item.checked"
        checked="checked"
      />
      <input
        type="checkbox"
        :value="item.key"
        @click="changeEvent(item)"
        v-else
      />
      {{ item.value }}
    </label>
  </div>
</template>
<script>
export default {
  name: 'checkbox-view',
  props: {
    verify: {
      type: Boolean,
      default: false
    },
    nonEmpty: Boolean,
    modelValue: Array,
    options: Array,
    onChange:Function,
  },
  data() {
    return {
      id:
        'checkbox-view' +
        new Date().getTime() +
        Math.floor(Math.random() * 100000),
      checkboxArray: [],
      checkList:[],
    }
  },
  mounted: function () {
    this.checkList = this.modelValue;
    this.bindData()
  },
  watch: {
    modelValue(val) {
      this.bindData()
    }
  },
  methods: {
    bindData() {
      for (let i = 0, len1 = this.options.length; i < len1; i++) {
        let isChecked = false
        let item = this.options[i]
        for (let j = 0, len2 = this.modelValue.length; j < len2; j++) {
          let subItem = this.modelValue[j]
          if (item.key.toString() === subItem.toString()) {
            isChecked = true
          }
        }
        item.checked = isChecked
      }
      this.checkboxArray = Object.assign(this.options, {})
    },
    changeEvent(item) {
      item.checked = !item.checked
      let checkListIds = []
      for (let i = 0, len = this.checkboxArray.length; i < len; i++) {
        let item = this.checkboxArray[i]
        if (item.checked) {
          checkListIds.push(item.key)
        }
      }
      this.checkList = checkListIds;
      if (this.verify) {
        this.verifyValue()
      }
      this.$emit('update:modelValue', checkListIds);
    },
    //验证输入是否正确
    verifyValue() {
      if ((this.checkList == null || this.checkList.length == 0) && this.nonEmpty) {
        $('#' + this.id).attr('title', '必选项')
        $('#' + this.id).css('color', '#da5f4d').css('border-color', '#da5f4d')
        $('#' + this.id).tooltip('show');
        return false;
      } else {
        $('#' + this.id).tooltip('destroy')
        $('#' + this.id).css('color', '#4d4d4d').css('border-color', '#CED4DA')
        return true;
      }
    }
  }
}
</script>

<style scoped>
.checkbox{
  margin: 0!important;
}
.checkbox label {
  padding-right: 10px;
}
.checkbox.form-control {
  border-style: dotted;
}

</style>


