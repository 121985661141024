<template>
  <aside class="main-sidebar" id="main-sidebar">
    <section class="sidebar" style="height: auto">
      <ul class="sidebar-menu">
        <template v-for="menu in menuData">
          <li class="treeview" :data-menu-id="menu.id" v-if="menu.parentId == $Store.state.currentMenu" v-bind:key="menu.id">
            <a @click="menuEvent(menu)">
              <i :class="menu.icon"></i> <span style='margin-left: 5px'>{{ menu.name }}</span>
              <span class="pull-right-container" v-if="menu.route == '' || menu.route == null">
                <i class="fa fa-angle-left pull-right"></i>
              </span>
            </a>
            <ul class="treeview-menu menu-open">
              <template v-for="submenu in menuData">
                <li class="treeview" v-if="submenu.parentId == menu.id" :data-menu-id="submenu.id" v-bind:key="submenu.id">
                  <a @click="menuEvent(submenu)">
                    <i :class="submenu.icon"></i> <span>{{ submenu.name }}</span>
                  </a>
                </li>
              </template>
            </ul>
          </li>
        </template>
      </ul>
    </section>
  </aside>
</template>
<script>
import menuData from '../../menu';
import menuUtils from '../../menuUtils';
import MenuUtils from '../../menuUtils';

export default {
  name: 'main-sidebar',
  data: function () {
    return {
      menuData: menuData,
      showDetail: false
    };
  },
  mounted() {
    document.addEventListener('click', e => {
      let userIcon = document.getElementById('user-icon');
      let userDetailPanel = document.getElementById('user-detail-panel');
      if (!userIcon?.contains(e.target) && !userDetailPanel?.contains(e.target)) {
        this.showDetail = false;
      }
    });
  },
  methods: {
    menuEvent(menu) {
      if (menu.route != null && menu.route != '') {
        MenuUtils.routerQueryTo(menu.route);
      }
    },
    signOut: function () {
      this.$router.push('/login');
    },
    userDetailEvent() {
      this.showDetail = true;
    },
    hideUserDetail() {
      this.showDetail = false;
    }
  }
};
</script>
