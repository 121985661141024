<template>
  <div style="font-family: PingFang SC;font-style: normal;font-weight: normal;font-size: 16px;line-height: 30px;color: #666666;flex: 1;">
    {{ dayTime }}
  </div>
</template>

<script>
import DateTimeUtils from "@/utils/DateTimeUtils";

export default {
  name: "RunTime",
  data() {
    return { dayTime: "" };
  },
  mounted() {
    this.updateTime();
    this.timeId = setInterval(this.updateTime, 1000);
  },
  beforeDestroy: function () {
    if (this.timeId) {
      clearInterval(this.timeId);
    }
  },
  methods: {
    updateTime() {
      this.dayTime = DateTimeUtils.formatDate(
        new Date().getTime(),
        "yyyy年MM月dd日 hh:mm:ss"
      );
    }
  }
};
</script>
