<template>
  <div :id='id'>
    <div class='form-control' style='display: flex;cursor: pointer' @click='showListEvent'
         :style='{borderRadius:(isFocus?"6px 6px 0 0":"6px")}'>
      <div style='flex: 1;color: #444;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;'>
        {{ selectItem ? selectItem[valueField] : text }}
      </div>
      <span class='fa fa-sort-desc' style='color:rgb(136 136 136)'></span>
    </div>
    <div v-if='isFocus' style='position: fixed;z-index: 2000;' :style='{top:(y+"px"),left:(x+"px"),width:(width+"px")}'>
      <div class='form-control' style='border-radius: 0;padding: 4px;height: 42px'>
        <input style='border: 1px solid #b6bbc1;background:none;width:100%;outline:none;height:32px'
               v-model='input' placeholder='请输入查询关键字' @keyup.enter='submitEvent' v-on:input="changeSearch"/>
      </div>
      <div
        style='max-height: 240px;min-height: 30px;background: #ffffff;border-radius: 0 0 8px 8px;overflow-y: auto;border: #ced4da 1px solid;border-top:none'
      >
        <div class='over-active'
             style='line-height: 30px;padding-left:8px;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;cursor: pointer'
             @click='clickEvent()'>请选择
        </div>
        <div class='over-active' v-bind:key='index' v-for='(item,index) in searchResult'
             style='line-height: 30px;padding-left:8px;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;cursor: pointer'
             @click='clickEvent(item)'>
          <span>{{ item[valueField] }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StorageUtils from "@/utils/StorageUtils";
import { debounce } from "@/utils/LimitUtils";

export default {
  name: "service-select-view",
  props: {
    text:{
      type: String,
      default: "请选择"
    },
    url: {
      type: String,
      default: ""
    },
    parameter: {
      type: Object,
      default: {}
    },
    keyField: {
      type: String,
      default: "id"
    },
    valueField: {
      type: String,
      default: "name"
    },
    selectEvent:{
      type:Function,
      default:()=>{}
    }
  },

  data: function() {
    return {
      id: "service-select-view" + new Date().getTime(),
      selectItem: null,
      searchResult: [],
      showText: "请选择",
      input: "",
      isFocus: false,
      x: 0,
      y: 0
    };
  },

  mounted: function() {
    this.bindData();
  },

  methods: {
    changeSearch() {
      debounce(() => {
        this.submitEvent();
      }, 600);
    },
    bindData: function() {
      this.searchResult = JSON.parse(StorageUtils.localStorageGet(this.url));
    },
    showListEvent() {
      if (!this.isFocus) {
        this.isFocus = true;
        this.y = $("#" + this.id).offset().top + 33;
        this.x = $("#" + this.id).offset().left;
        this.width = $("#" + this.id).outerWidth();

        setTimeout(() => {
          if (document.addEventListener) {
            document.addEventListener("DOMMouseScroll", this.scrollEvent, false);
            document.addEventListener("click", this.clickHideEvent, false);
          }
          window.addEventListener("mousewheel", this.scrollEvent, { passive: false });
          window.addEventListener("click", this.clickHideEvent, { passive: false });
        }, 30);
      } else {
        this.isFocus = false;
        if (document.removeEventListener) {
          document.removeEventListener("DOMMouseScroll", this.scrollEvent);
          document.removeEventListener("click", this.clickHideEvent);
        }
        window.removeEventListener("mousewheel", this.scrollEvent);
        window.removeEventListener("click", this.clickHideEvent);
      }
    },

    clickHideEvent(e) {
      if (!(e.pageX > this.x - 20 && e.pageX < this.x + this.width + 20 && e.pageY > this.y && e.pageY < this.y + 42)) {
        if (this.isFocus) {
          this.isFocus = false;
          if (document.removeEventListener) {
            document.removeEventListener("DOMMouseScroll", this.scrollEvent);
            document.removeEventListener("click", this.clickHideEvent);
          }
          window.removeEventListener("mousewheel", this.scrollEvent);
          window.removeEventListener("click", this.clickHideEvent);
        }
      }
    },

    scrollEvent(e) {
      if (!(e.pageX > this.x && e.pageX < this.x + this.width && e.pageY > this.y && e.pageY < this.y + 300)) {
        e.preventDefault && e.preventDefault();
      }
    },

    submitEvent: function() {
      if (this.parameter != null) {
        this.parameter.search = this.input;
        this.parameter.pageSize = 100;
      }
      this.$Http(this.url, this.parameter, "GET").then(result => {
        this.searchResult = result.data.data.data;
        StorageUtils.localStorageSet(this.url, JSON.stringify(this.searchResult));
      });
    },
    clickEvent: function(item) {
      this.selectItem = item;
      this.$emit("update:modelValue", item[this.keyField]);
      this.selectEvent&&this.selectEvent(item[this.keyField]);
    }
  }
};
</script>

<style scoped>
.over-active:hover {
  background: #5897fb;
  color: #fff
}
</style>


