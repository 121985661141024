<template>
  <header class='main-header' id='main-header'>
    <!-- Logo图标 -->
    <a class='logo' href='#' style='box-shadow: 3px 3px 5px #f1f1f1'>
<!--      <img src='../../assets/images/logo.svg' style='width: 20px;height:20px;margin-top: -3px' />-->
      <span style='margin-left: 5px;'>麦祥商城后台管理</span>
    </a>
    <nav class='navbar navbar-static-top' style='box-shadow: rgb(241, 241, 241) 3px 3px 5px'>
      <!-- 左侧滑动按钮-->
      <!--      <a class="sidebar-toggle" role="button" href="#" data-toggle="offcanvas">-->
      <!--        <span class="sr-only">切换导航</span>-->
      <!--      </a>-->
      <div style='height:60px;display: flex;padding: 20px  15px 0 15px;float: left'>
        <div class='open-item' style='min-width: 40px;' @click='preEvent'>
          <span class='fa fa-angle-double-left' style='display: block;font-size: 20px'></span>
        </div>
        <div style='width: 480px;overflow: hidden'>
          <div style='display: flex;transition: margin-left 0.5s' :style='{marginLeft:-selectIndex*120+"px"}'>
            <template v-for='item,index in menuData'>
              <div class='open-item' :class="$Store.state.currentMenu==item.id?'open-item active':'open-item'"
                   v-if="item.parentId == '' || item.parentId == null">
                <div style='flex: 1' @click='selectMenu(item)'>{{ item.name }}</div>
              </div>
            </template>
          </div>
        </div>
        <div class='open-item' style='min-width: 40px;' @click='nextEvent'>
          <span class='fa fa-angle-double-right' style='display: block;font-size: 20px'></span>
        </div>
      </div>
      <div class='navbar-custom-menu' id='navbar-custom-menu-right'>
        <ul class='nav navbar-nav'>
          <li class='dropdown notifications-menu'>
            <a href='#' class='dropdown-toggle' data-toggle='dropdown'>
              <i class='icon-9'
                 style='font-size: 20px;display: flex;align-items: center;line-height: 30px;color: #b7b8c0'></i>
<!--              <span class='label label-danger'>0</span>-->
            </a>
            <div class='dropdown-menu' style='width: 300px;padding: 10px'>
              <div style='font-size: 13px;color: #aaaaaa;margin-bottom: 3px'>暂无信息</div>
<!--              <div v-for='item in 3' class='todo-item'>-->
<!--                <div style='flex:1'>-->
<!--                  <div>数采仪001</div>-->
<!--                  <div style='font-size: 13px;color: #aaaaaa;margin: 6px 0'>报警类型：数据读取链接中断</div>-->
<!--                  <div style='display: flex;font-size: 13px'>-->
<!--                    <div style='background: #00c2ad;color: #ffffff;border-radius: 3px;padding: 4px 10px'>处理</div>-->
<!--                    <div-->
<!--                      style='background: #ffffff;color: #ff8e8e;border-radius: 3px;padding: 4px 10px;border:solid 1px #ff8e8e;margin: 0 5px'>-->
<!--                      忽略-->
<!--                    </div>-->
<!--                    <div-->
<!--                      style='background: #ffffff;color: #aaaaaa;border-radius: 3px;padding: 4px 10px;border:solid 1px #aaaaaa'>-->
<!--                      查看详情-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div>-->
<!--                  <div-->
<!--                    style='background: #fef3e6;color: #fcbc77;text-align: center;padding:2px 5px;border-radius: 4px;font-size: 13px'>-->
<!--                    待处理-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
            </div>
          </li>
          <!-- 账号信息 -->
          <li class='dropdown user user-menu'>
            <a class='dropdown-toggle' href='#' data-toggle='dropdown'
               style='height: 60px;display: flex;align-items: center'>
              <img :src="require('../../assets/images/user-icon.png')" class='user-image' alt='User Image'>
              <span class='hidden-xs' style='white-space:nowrap;color: #333333;font-size: 16px'>管理员</span>
              <span class='fa fa-caret-down' style='margin-left: 10px;font-size: 16px;color: #333333'></span>
            </a>
            <div class='dropdown-menu' style='margin: 5px;border-radius: 4px;padding: 10px;width: 300px'>
              <div class='user-bg'>
                <img src='../../assets/images/user-icon.png'
                     style='width: 54px;height: 54px;border-radius: 50%;border:2px #ffffff solid;margin: 10px' />
                <div>
                  <div>
                    <span style='font-size: 20px;font-weight: bold;color: #ffffff;margin-right: 10px'>{{ ($Store.storeGet("user")||{}).nickName}}</span>
                    <span
                      style='color: #ffdd00;background: #ffff0033;border-radius: 10px;padding: 4px 8px;font-size: 13px'>管理员</span>
                  </div>
<!--                  <div style='color: #eeeeff;font-size: 13px'>信息技术开发部</div>-->
                </div>
              </div>
              <div style='display: flex;align-items: center;color: #aaaaaa;padding: 10px;cursor: pointer'
                   @click='changeEvent'>
                <span class='fa fa-question-circle-o'></span>
                <div style='flex:1;padding-left: 10px'>修改密码</div>
              </div>
              <div style='display: flex;align-items: center;color: #aaaaaa;padding: 10px;cursor: pointer' @click='signOut'>
                <span class='fa fa-sign-out'></span>
                <div style='flex:1;padding-left: 10px'>退出登录</div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <!--      <div-->
      <!--        style='height: 60px;color:#d4e5fa;font-size: 14px;float: right;padding-right: 15px;display: flex;align-items: center;position: relative'>-->
      <!--        <input type='text' class='form-control'-->
      <!--               style='width: 200px;border-radius: 18px;height: 36px;background: #f4f5f6;border: none'-->
      <!--               placeholder='合同名称、编号' />-->
      <!--        <span class='icon-10' style='position: absolute;right: 30px;top:24px;color: #999999'></span>-->
      <!--      </div>-->
    </nav>
  </header>
</template>

<script>
import menuData from '../../menu';
import MenuUtils from '@/menuUtils';

export default {
  name: 'main-header',
  data: function() {
    return {
      selectIndex: 0,
      menuData: menuData
    };
  },
  props: {
    changePassword: Function
  },
  mounted() {
    setTimeout(()=>{
      this.selectMenu(menuData[0])
    },300)
  },
  methods: {
    selectMenu(item) {
      this.$Store.storeSet('currentMenu', item.id);
      for (let i = 0, len = menuData.length; i < len; i++) {
        let menu = menuData[i];
        if (menu.parentId == item.id) {
          if (menu.route != null && menu.route != '') {
            MenuUtils.routerQueryTo(menu.route);
            $.zhoujl.menuActive(menu.id);
            break;
          }
        }
      }
    },
    changeEvent: function () {
      this.changePassword && this.changePassword();
    },
    preEvent() {
      if (this.selectIndex <= 0) {
        return;
      }
      this.selectIndex--;
    },

    nextEvent() {
      let count=0;
      for (let i = 0, len = menuData.length; i < len; i++) {
        let menu = menuData[i];
        if (menu.route != null && menu.route != '') {
          count++;
        }
      }
      if (this.selectIndex + 4 >= count) {
        return;
      }
      this.selectIndex++;
    },
    signOut: function() {
      this.$router.push('/login');
    },
    home: function() {
      this.$router.push('/index/home');
    },

    showLogEvent() {
      this.$Store.storeSet('showLog', true);
    },

    showFeedback() {
      this.$Store.storeSet('showFeedback', true);
    }
  }
};
</script>
<style>
.todo-item {
  width: 450px;
  border-radius: 6px;
  border: solid 1px #eeeeee;
  padding: 10px;
  display: flex;
  margin-bottom: 10px
}

.todo-item:hover {
  border-color: #535ef1;
}

.user-bg {
  background: url("../../assets/images/user-header.png");
  width: 280px;
  height: 80px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}


.open-item {
  height: 40px;
  line-height: 40px;
  min-width: 110px;
  padding: 0 8px 0 8px;
  background: #edeffe;
  margin: 0 10px 0 0;
  color: #535ef1;
  display: flex;
  align-items: center;
  text-align: center;
  border-radius: 8px 8px 0 0;
  cursor: pointer;
  font-size: 15px;
}

.open-item .fa {
  display: none;
}

.open-item.active {
  background: #535ef1;
  color: #ffffff;
}


.open-item.active .fa {
  display: block !important;
}
</style>
