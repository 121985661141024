import axios from 'axios';
import router, { tokenExpired } from './router';
import StorageUtils from './utils/StorageUtils';

/**
 * 配套config/index.js中的 proxyTable属性进行跨域请求（测试用）
 * @type {string}
 */
axios.defaults.baseURL = process.env.NODE_ENV == 'development' ? '/apis/' : window.globle.RequestUrl;

/**
 * axios实例
 * @timeout 超时时间
 */
const service = axios.create({ timeout: 30000 });

/**
 * request拦截器
 * @type {number}
 */
service.interceptors.request.use(
    config => {
      //token验证
      if (tokenExpired()||router.currentRoute._value.fullPath.indexOf('/goods') != -1) {
        config.headers.Authorization = StorageUtils.localStorageGet('token');
      } else {
        if (router.currentRoute._value.fullPath.indexOf('/login') == -1) {
          router.push({
            path: '/login',
            query: { redirect: router.currentRoute.fullPath }
          });
          StorageUtils.localStorageSet('token',null)
        }
      }
      return config;
    },
    error => {
      Promise.reject(error);
    }
);

/**
 * response拦截器
 */
service.interceptors.response.use(
    response => {
      $.zhoujl.unblockUI();
      if (response.data.success !== null && response.data.success !== undefined && !response.data.success) {
        $Message.error(response.data.msg);
        return Promise.reject(response);
      } else {
        //每次请求成功都自动续期token时间
        StorageUtils.localStorageSet('expiresTime', new Date(new Date().getTime() + 14400000));
      }
      return response;
    },
    error => {
      $.zhoujl.unblockUI();
      if (error.response && error.response.status == 401) {
        //401错误处理
        StorageUtils.localStorageSet('token',null);
        StorageUtils.localStorageSet('expiresTime',null);
        if (router.currentRoute._value.fullPath.indexOf('/login') == -1) {
          router.push({
            path: '/login',
            query: { redirect: router.currentRoute.fullPath }
          });
        }
      }
      if (error.response && error.response.status === 403) {
        //403错误处理
      }
      if (error.response && error.response.status === 404) {
        //404错误处理
      }
      if (error.response && error.response.status === 500) {
        //500错误处理
      }
      return Promise.reject(error.response);
    }
);

/**
 * get请求
 * @param url
 * @param params
 * @returns {*}
 */
export function get(url, params = {}) {
  params.t = new Date().getTime(); //get方法加一个时间参数,解决ie下可能缓存问题.
  return service({
    url: url,
    method: 'get',
    headers: {},
    params
  });
}

/**
 * post请求
 * @param url
 * @param data
 * @returns {*}
 */
export function post(url, data = {}) {
  //默认配置
  return service({
    url: url,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: JSON.stringify(data)
  });
}

/**
 * form表单post请求
 * @param url
 * @param data
 * @returns {*}
 */
function postFormData(url, data = {}) {
  return service({
    url: url,
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data: data,
    transformRequest: [
      function (data) {
        let ret = '';
        for (let item in data) {
          ret += encodeURIComponent(item) + '=' + encodeURIComponent(data[item]) + '&';
        }
        ret = ret.substring(0, ret.lastIndexOf('&'));
        return ret;
      }
    ]
  });
}

/**
 * put请求
 * @param url
 * @param data
 * @returns {*}
 */
function put(url, data = {}) {
  return service({
    url: url,
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: JSON.stringify(data)
  });
}

/**
 * delete请求
 * @param url
 * @param data
 * @returns {*}
 */
function deletes(url, data = {}) {
  return service({
    url: url,
    method: 'delete',
    headers: {},
    params: data
  });
}

/**
 * patch
 * @param url
 * @returns {*}
 */
function patch(url, data = {}) {
  return service({
    url: url,
    method: 'patch',
    headers: {},
    data: JSON.stringify(data)
  });
}

/**
 * 不支持 google 0.46版本
 * 普通下载使用：location.href 或 window.open
 * 下载用法样例
 * this.$Http("Report/IDCertExportExcleDownload",{},"DOWNLOAD").then(response => {
 *           let blob = new Blob([response.data], {
 *             type: "application/vnd.ms-excel"
 *           });
 *           if (window.navigator.msSaveOrOpenBlob) {
 *             navigator.msSaveBlob(blob);
 *           } else {
 *             let link = document.createElement('a');
 *             link.href = window.URL.createObjectURL(blob);
 *             link.click();
 *             window.URL.revokeObjectURL(link.href)
 *           }
 *         }
 * );
 * @param url
 * @param formData
 * @returns {*}
 */
export function download(url, formData = {}) {
  return service({
    method: 'POST',
    url: url,
    data: formData,
    responseType: 'blob'
  });
}

/**
 * @param url
 * @param formData   例：let data = new FormData();data.append('file', file[0]);
 * @returns {*}
 */
export function upload(url, formData, progressEvent) {
  // 文件上传
  return service({
    method: 'POST',
    url: url,
    data: formData,
    onUploadProgress: result => {
      if (progressEvent != null) {
        let process = ((result.loaded / result.total) * 100) | 0;
        progressEvent(process);
      }
    }
  });
}

/**
 * 处理格式化URL（/demo/{id}）
 * @param url
 * @param data
 * @returns {*}
 */
function render(url, data) {
  let re = /{([^]+)?}/;
  let match = '';
  while ((match = re.exec(url))) {
    url = url.replace(match[0], data[match[1]]);
  }
  return url;
}

const fetch = options => {
  let url = options.url;
  url = options.method.toUpperCase() === 'UPLOAD' ? options.url : render(url, options.data);
  switch (options.method.toUpperCase()) {
    case 'GET':
      return get(url, options.data);
    case 'POST':
      return post(url, options.data);
    case 'FORM':
      return postFormData(url, options.data);
    case 'PUT':
      return put(url, options.data);
    case 'DELETE':
      return deletes(url, options.data);
    case 'PATCH':
      return patch(url, options.data);
    case 'DOWNLOAD':
      return download(url, options.data);
    case 'UPLOAD':
      return upload(url, options.data);
    default:
      return service(options);
  }
};

/**
 * 提供一个http方法
 * url 访问路径 不包括域名和项目名
 * data 参数对象
 * method 请求方式
 *  */
export function http(url, data = {}, method = 'GET', showLoading = true) {
  if (showLoading) {
    $.zhoujl.blockPage('loading...');
  }
  const options = { url: url, data: data, method: method };
  return fetch(options).catch(error => {
    if (error != null && error.status > 200 && error.status < 300) {
      $Message.error(error.statusText);
    }
    throw error;
  });
}
