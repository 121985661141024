<template>
  <div id="layout-main" style="background: rgb(44, 59, 91);min-width: 1440px;">
    <!-- 顶部导航栏 -->
    <main-header :changePassword="changeEvent"/>
    <!-- 左侧菜单 -->
    <main-sidebar/>
<!--    <breadcrumb-view-->
<!--        v-if="$Store.storeGet('breadcrumb') != null &&$Store.storeGet('breadcrumb').length > 0"-->
<!--        :style="{ marginLeft: isShow ? '160px' : 0 }"-->
<!--        :breadcrumblist="$Store.storeGet('breadcrumb')||[]"-->
<!--    ></breadcrumb-view>-->
    <!-- 主要内容 -->
    <div class="content-wrapper" id="main-content" style="min-height: 637px;overflow-y: auto;">
      <router-view v-slot="{ Component }">
        <keep-alive :include="$Store.storeGet('keepAlive')" :max="3">
          <component :is="Component"></component>
        </keep-alive>
      </router-view>
    </div>
    <!-- 版权Copyright -->
<!--    <copyright/>-->
    <!-- 右侧展开菜单 -->
<!--    <control-sidebar />-->
    <modal-view ref="category-edit" modalTitle="修改密码" :submitEvent="submitEvent">
      <div class="row">
        <div class="col-sm-12 form-group">
          <label class="col-sm-3 control-label" style="padding-top: 5px">旧密码<span style="color: red">*</span></label>
          <div class="col-sm-9">
            <input-view type="password" v-model="selectItem.password" class="form-control input-sm" placeholder="请输入旧密码" />
          </div>
        </div>
        <div class="col-sm-12 form-group">
          <label class="col-sm-3 control-label" style="padding-top: 5px">新密码<span style="color: red">*</span></label>
          <div class="col-sm-9">
            <input-view type="password" v-model="selectItem.newPassword" class="form-control input-sm" placeholder="请输入新密码6~15位" />
          </div>
        </div>
        <div class="col-sm-12 form-group">
          <label class="col-sm-3 control-label" style="padding-top: 5px">新密码<span style="color: red">*</span></label>
          <div class="col-sm-9">
            <input-view type="password" v-model="selectItem.newPassword1" class="form-control input-sm" placeholder="请再次输入新密码6~15位" />
          </div>
        </div>
      </div>
    </modal-view>
  </div>
</template>
<script>
import StringUtils from '@/utils/StringUtils';
import SHA1 from 'crypto-js/sha1';
export default {
  data: function () {
    return {
      isShow:true,

      isRouterAlive: true,
      selectOpenPage: "",
      openPage: [],
      selectItem:{}
    }
  },

  mounted: function () {
    this.init()
  },

  methods: {
    init: function () {
      $.zhoujl.blockPage()
      $('body').removeClass()
      $('body').addClass('skin-blue sidebar-mini sidebar-expanded-on-hover')
      $.zhoujl.layoutInit()
      $.zhoujl.unblockUI()
    },
    changeEvent() {
      this.$refs['category-edit'].show();
    },
    submitEvent() {
      if (StringUtils.isNullOrEmpty(this.selectItem.password)) {
        $Message.error('请输入旧密码');
        return;
      }
      if (this.selectItem.newPassword !== this.selectItem.newPassword1) {
        $Message.error('新密码两次输入不一致');
        return;
      }
      if (this.selectItem.newPassword.length < 6 || this.selectItem.newPassword.length > 15) {
        $Message.error('新密码长度6-15位');
        return;
      }

      let data = {
        password: SHA1(this.selectItem.password).toString(),
        newPassword: SHA1(this.selectItem.newPassword).toString()
      };
      this.$Http('/changePassword', data, 'POST').then(result => {
        $Message.success('密码修改成功');
        this.$refs['category-edit'].hide();
      });
    }
  }
}
</script>
