
import Vuex from "vuex";
import state from "../vuex/state";
import StorageUtils from "@/utils/StorageUtils";

let store=new Vuex.Store({
  state:state,

  mutations:{
    set:(state,object)=>{
      state[object.key]=object.value;
    },
  },
  actions:{
    set:(context,object)=>{
      context.commit("set",object);
    },
  }
});


/**
 * 设置状态
 * @param key 状态名称
 * @param value 状态值
 * @param storage 是否加入到缓存
 */
export const storeSet = function (key, value, storage = true) {
  if (storage) {
    StorageUtils.localStorageSet(key, JSON.stringify(value || ''))
  }
  store.commit('set', {key, value})
}

/**
 * 读取状态
 * @param key 状态名称
 * @returns {*} 返回状态值
 */
export const storeGet = function (key, storage = true) {
  let result = store.state[key]
  if (result) {
    return result
  }
  if (storage) {
    if (StorageUtils.localStorageGet(key) == null) {
      return null
    }
    try {
      return JSON.parse(StorageUtils.localStorageGet(key))
    } catch (e) {
      return StorageUtils.localStorageGet(key)
    }

  } else {
    return null
  }
}

/**
 * 设置状态 支持异步、then
 * @param key 状态名称
 * @param value 状态值
 * @param storage 是否加入到缓存
 */
export const dispatch = function (key, value, storage = true) {
  if (storage) {
    StorageUtils.localStorageSet(key, JSON.stringify(value))
  }
  return new Promise((resolve, reject) => {
    store.dispatch('set', {key, value}).then((result) => {
      resolve(result)
    }).catch((error) => {
      reject(error)
    })
  })
}

store.storeSet=storeSet;
store.storeGet=storeGet;
store.dispatch=dispatch;
export default store;


