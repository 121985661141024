<template>
  <div class="radio" style="margin: 0" @change="changeEvent">
    <label v-for="item in options">
      <input type="radio" :value="item.key" v-model="modelValue" />{{
        item.value
      }}
    </label>
  </div>
</template>

<script>
export default {
  name: 'radio-view',
  props: {
    modelValue: [String, Number],
    options: Array
  },
  data() {
    return {

    }
  },
  methods: {
    changeEvent(){
      this.$emit('update:modelValue', this.modelValue)
    }
  }
}
</script>

<style scoped>
.radio label {
  padding-right: 10px;
}

.radio.form-control {
  border-style: dotted;
}
</style>
