<template>
  <input readonly :id="id" />
</template>

<script>
import moment from 'moment'
import 'daterangepicker'
import '../../assets/plugins/daterangepicker/daterangepicker.css'
import StringUtils from '../../utils/StringUtils'
export default {
  data: function () {
    return {
      id:
        'range-date-time-view' +
        new Date().getTime() +
        Math.floor(Math.random() * 100000)
    }
  },
  name: 'range-date-time-view',
  props: {
    format: String,
    timePicker: Boolean,
    modelValue: Array,
    //时间组件是否跟着滑的属性 免得被遮挡 点不到  true跟着页面滑 false不跟
    scroll:{
      type:Boolean,
      default:true
    },
  },
  mounted: function () {
    this.init()
  },
  watch: {
    modelValue(val) {
      $('#' + this.id).val(val[0] + ' 至 ' + val[1])
    }
  },
  methods: {
    init: function () {
      if (this.modelValue != null) {
        $('#' + this.id).val(this.modelValue[0] + ' 至 ' + this.modelValue[1])
      }
      let options={
        showDropdowns: true, //年月份下拉框
        autoUpdateInput: false, //关闭自动赋值，使初始值为空
        timePicker: this.timePicker == null ? false : this.timePicker, //显示时分时间选择
        timePicker24Hour: true, //设置小时为24小时制
        ranges : {
          '今日': [moment().startOf('day'), moment()],
          '昨日': [moment().subtract('days', 1).startOf('day'), moment().subtract('days', 1).endOf('day')],
          '最近7日': [moment().subtract('days', 6), moment()],
          '最近30日': [moment().subtract('days', 29), moment()],
          '最近一年': [moment().subtract('days', 365), moment()]
        },
        locale: {
          format: this.format || 'YYYY-MM-DD',
          applyLabel: '确定',
          cancelLabel: '取消',
          fromLabel : '起始时间',
          toLabel : '结束时间',
          customRangeLabel : '自定义',
          daysOfWeek: ['日', '一', '二', '三', '四', '五', '六'],
          monthNames: [
            '一月',
            '二月',
            '三月',
            '四月',
            '五月',
            '六月',
            '七月',
            '八月',
            '九月',
            '十月',
            '十一月',
            '十二月'
          ],
          firstDay: 1
        }
      };
      if(this.scroll){
        options.parentEl=$("#" + this.id).parent();
      }
      $('#' + this.id).daterangepicker(options)
      $('#' + this.id).on('apply.daterangepicker', (ev, picker) => {
        let startText = picker.startDate.format(this.format || 'YYYY-MM-DD')
        let endText = picker.endDate.format(this.format || 'YYYY-MM-DD')
        this.$emit('update:modelValue', [startText, endText])
      })

      $('#' + this.id).on('show.daterangepicker', (ev, picker) => {
        picker.elementChanged()
      })
    }
  }
}
</script>
