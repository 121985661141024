import { createApp } from "vue";
import App from "@/App.vue";
import Vuex from "vuex";
import VueRouter from 'vue-router'
import router from '@/router'
import {upload,http} from '@/http'
import {errorHandler} from '@/errorHandler'
import store from '@/vuex/store';
import Enum from '@/model/Enum'
import install from '@/install'
import Mitt from 'mitt';
const mitt = Mitt();

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import '@/assets/css/style-dashboard.css';
import '@/assets/css/style-component.css';
import '@/assets/css/skin-blue.css';
import '@/assets/css/style.css';
import '@/assets/css/font-awesome.min.css';
import '@/assets/js/jquery.cookie.js';
import '@/assets/js/bootbox.js';
import '@/assets/js/jquery.blockUI.js';
import '@/assets/js/zhoujl.layout.js';

import message from '@/assets/plugins/message/message.min.js'
window.$Message=message;

const app=createApp(App);
app.use(router).use(store).use(Vuex).use(VueRouter).use(install);
// app.config.errorHandler = errorHandler;
app.config.globalProperties.$Store= store;
app.config.globalProperties.$Http = http;
app.config.globalProperties.$Upload = upload;
app.config.globalProperties.$Enum = Enum;
app.config.globalProperties.$EventBus = {
    $emit: mitt.emit,
    $on: mitt.on,
    $off: mitt.off
}
app.mount("#app");
