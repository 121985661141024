<template>
  <div>
    <div :key="item.id" v-for="(item, index) in files" class="attachment-box" style="float: left; margin: 0 5px 5px 0; background: #ffffff">
      <span class="attachment-icon" :class="getFileIcon(item.ext)" v-if="getFileIcon(item.ext) != 'fa fa-image-o'"></span>
      <img v-else :src="item.url" style="width: 100%; height: 100px; object-fit: contain" />
      <div class="attachment-info">
        <div class="attachment-name">
          <i class="fa fa-paperclip" style="margin-right: 3px"></i>
          <div class="file-name">{{ item.fileName }}</div>
          <div class="file-download" style="line-height: 14px" @click="downloadEvent(item)">
            <i class="fa fa-cloud-download"></i>
          </div>
          <div @click="deleteEvent(item)"><i class="fa fa-times-circle"></i></div>
        </div>
      </div>
    </div>
    <div
        :id="id"
        v-show="files.length < maxCount"
        data-toggle="tooltip"
        data-placement="bottom"
        data-trigger="manual"
        class="attachment-box add"
        style="float: left; margin-right: 5px"
        @click="uploadEvent"
    >
      <span class="attachment-icon">
        {{ loading ? progress + '%' : '+' }}
      </span>
      <div class="attachment-info">
        <div class="attachment-name">
          <i class="fa fa-paperclip" style="margin-right: 3px"></i>
          <div class="file-name">上传文件限{{ maxSize }}M以下</div>
        </div>
      </div>
      <input type="file" style="display: none" />
    </div>
  </div>
</template>

<script>
import StringUtils from '@/utils/StringUtils';

export default {
  name: 'FileUploadView',
  props: {
    modelValue: Array,
    maxSize: {
      type: Number,
      default: 2
    },
    maxCount: {
      type: Number,
      default: 1
    },
    verify: {
      type: Boolean,
      default: false
    },
    nonEmpty: Boolean
  },
  data: function () {
    return {
      id: 'fileUpload' + new Date().getTime() + Math.floor(Math.random() * 1000),
      loading: false,
      progress: 0,
      files: [],
      localUrls: []
    };
  },
  watch: {
    modelValue: function () {
      this.files = JSON.parse(JSON.stringify(this.modelValue));
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.files = JSON.parse(JSON.stringify(this.modelValue));
      let fileInputObject = $('#' + this.id).find('input');
      fileInputObject.change(() => {
        let filePath = fileInputObject.val();
        if (StringUtils.isNullOrEmpty(filePath)) {
          return;
        }
        let file = fileInputObject[0].files[0];
        if (file.size > this.maxSize * 1024 * 1024) {
          window.$Message.warning('上传文件超过大小限制');
          return;
        }
        let ext = '';
        let startIndex = filePath.lastIndexOf('.');
        if (startIndex !== -1) {
          ext = filePath.substring(startIndex + 1, filePath.length).toLowerCase();
        }
        let reads = new FileReader();
        reads.readAsDataURL(file);
        reads.onload = reader => {
          let data = new FormData();
          data.append('file', file);
          this.progress = 0;
          this.loading = true;
          this.$Upload('fileUpload', data, progress => {
            this.progress = progress;
          }).then(result => {
            fileInputObject.val('');
            this.files.push({
              id: result.data.data.id,
              fileName: file.name,
              ext: '.' + ext,
              size: file.size,
              url: result.data.data.url
            });
            this.localUrls.push(reader.target.result);
            this.$emit('update:modelValue', this.files);
            this.progress = 100;
            this.loading = false;
            if (this.verify) {
              this.verifyValue();
            }
          });
        };
      });
    },

    getFileIcon(ext) {
      let type = {
        '.jpg': 'fa fa-image-o',
        '.jpeg': 'fa fa-image-o',
        '.png': 'fa fa-image-o',
        '.bmp': 'fa fa-image-o',
        '.gif': 'fa fa-image-o',

        '.pdf': 'fa fa-file-pdf-o',
        '.txt': 'fa fa-file-text-o',
        '.docx': 'fa fa-file-word-o',
        '.xlsx': 'fa fa-file-excel-o',
        '.mp3': 'fa fa-file-audio-o',
        '.doc': 'fa fa-file-word-o',
        '.xls': 'fa fa-file-excel-o',

        '.mp4': 'fa fa-file-movie-o',
        '.avi': 'fa fa-file-movie-o',
        '.rm': 'fa fa-file-movie-o',
        '.rmvb': 'fa fa-file-movie-o',
        '.flv': 'fa fa-file-movie-o',
        '.mpg': 'fa fa-file-movie-o',
        '.wma': 'fa fa-file-movie-o',
        '.3gp': 'fa fa-file-movie-o'
      };
      return type[ext] || 'fa fa-file-o';
    },

    fileSize(val) {
      if (val === 0) {
        return '0 B';
      }
      let k = 1024;
      let sizeUnit = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
      let i = Math.floor(Math.log(val) / Math.log(k));
      return Math.round((val / Math.pow(k, i).toPrecision(3)) * 100) / 100 + ' ' + sizeUnit[i];
    },

    verifyValue() {
      if (this.files.length === 0 && this.nonEmpty) {
        $('#' + this.id).attr('title', '文件不能为空');
        $('#' + this.id).css('border-color', '#da5f4d');
        setTimeout(() => {
          $('#' + this.id).tooltip('show');
        }, 30);
      } else {
        $('#' + this.id).tooltip('destroy');
        $('#' + this.id).css('border-color', '#eeeeee');
      }
    },

    uploadEvent() {
      $('#' + this.id)
          .find('input')
          .click();
    },

    deleteEvent(file) {
      for (let i = 0, len = this.files.length; i < len; i++) {
        let item = this.files[i];
        if (item.id === file.id) {
          this.files.splice(i, 1);
          this.localUrls.splice(i, 1);
          break;
        }
      }
      this.$emit('update:modelValue', this.files);
      if (this.verify) {
        this.verifyValue();
      }
    },

    downloadEvent(item) {
      let link = document.createElement('a');
      link.download = item.fileName;
      link.style.display = 'none';
      link.target = '_blank';
      link.href = item.url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};
</script>

<style scoped>
.attachment-box.add {
  float: left;
  margin: 0 5px 5px 0;
}

.attachment-icon.fa {
  text-align: center;
  line-height: 60px;
  width: 100%;
  font-size: 50px !important;
}

.fa.fa-times-circle {
  font-size: 18px;
  color: #cc2222;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 2px;
}
</style>
