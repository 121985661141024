<template>
  <input
    v-on:input="inputEvent"
    v-on:propertychange="inputEvent"
    data-toggle="tooltip"
    :data-placement="placement"
    data-html="true"
    data-trigger="manual"
    :value="modelValue"
    :id="id"
  />
</template>

<!--属性-->
<!--non-empty：是否必填【true、false】-->
<!--input-type：输入类型【count、price、phone、id-card、email、number】-->
<!--min-size：输入最短长度-->
<!--max-size：输入最长长度-->
<!--tip:填写内容说明-->
<script>
import StringUtils from '../../utils/StringUtils';
import EventBusUtils from '../../utils/EventBusUtils';
export default {
  data: function () {
    return {
      id: 'input-view' + new Date().getTime() + Math.floor(Math.random() * 100000),
      isShowTips: false
    };
  },
  name: 'input-view',
  props: {
    modelValue: String,
    placement: {
      type: String,
      default: 'bottom'
    },
    verify: {
      type: Boolean,
      default: false
    },
    nonEmpty: {
      type: Boolean,
      default: false
    },
    inputType: String,
    minSize: [String, Number],
    maxSize: [String, Number],
    tip: String
  },

  mounted: function () {
    this.init();
  },

  destroyed: function () {},

  methods: {
    init: function () {},
    showTips: function (object, tips) {
      $(object).attr('title', tips);
      if (!this.isShowTips) {
        this.isShowTips = true;
        $(object).tooltip('show');
        $(object).css('color', '#da5f4d').css('border-color', '#da5f4d');
      }
    },

    hideTips: function (object) {
      $(object).tooltip('destroy');
      $(object).css('color', '#4d4d4d').css('border-color', '#CED4DA');
      this.isShowTips = false;
    },

    inputEvent(e) {
      this.verifyValue($(e.target));
    },
    //验证
    verifyValue: function (object) {
      let text = object.val();
      let isSuccess = true;
      let showMessage = '';

      if (
        (this.nonEmpty !== null && this.nonEmpty && StringUtils.isNullOrEmpty(text)) ||
        (this.minSize !== null && text.length < this.minSize) ||
        (this.maxSize !== null && text.length > this.maxSize)
      ) {
        isSuccess = false;
        if (!StringUtils.isNullOrEmpty(this.minSize) && !StringUtils.isNullOrEmpty(this.maxSize)) {
          showMessage += '长度为' + this.minSize + '-' + this.maxSize + '个字符<br/>';
        } else if (this.minSize !== null) {
          showMessage += '长度不少于' + this.minSize + '个字符<br/>';
        } else if (this.maxSize !== null) {
          showMessage += '长度为1-' + this.maxSize + '个字符<br/>';
        } else {
          showMessage += '长度不少于1个字符<br/>';
        }
      }

      if (!StringUtils.isNullOrEmpty(this.inputType)) {
        switch (this.inputType) {
          case 'number':
            if (!StringUtils.isNumeric(text)) {
              isSuccess = false;
              showMessage += '请输入数字<br/>';
            }
            break;
          case 'count':
            if (!StringUtils.isPositiveInteger(text)) {
              isSuccess = false;
              showMessage += '请输入正整数<br/>';
            }
            break;
          case 'price':
            if (!StringUtils.isPositiveInteger(text) && !StringUtils.isPositiveDecimal(text)) {
              isSuccess = false;
              showMessage += '请输入正小数<br/>';
            }
            break;
          case 'phone':
            if (!StringUtils.isPhone(text)) {
              isSuccess = false;
              showMessage += '手机号码不正确<br/>';
            }
            break;
          case 'id-card':
            if (!StringUtils.isIdCard(text)) {
              isSuccess = false;
              showMessage += '身份证号码不正确<br/>';
            }
            break;
          case 'email':
            if (!StringUtils.isEmail(text)) {
              isSuccess = false;
              showMessage += '邮箱地址格式不正确<br/>';
            }
            break;
          default:
            break;
        }
      }

      if (!isSuccess) {
        if (!StringUtils.isNullOrEmpty(this.tip)) {
          showMessage = this.tip;
        }
        this.showTips(object, showMessage);
      } else {
        this.hideTips(object);
      }
      this.$emit('update:modelValue', text);
      return isSuccess;
    }
  }
};
</script>
<style>
.tooltip-inner {
  max-width: 250px;
  text-align: left;
  padding: 3px 8px 3px 8px;
}
</style>
