<template>
  <footer class="main-footer" id="main-footer" style="padding: 5px">
    <div class="pull-right hidden-xs"><b>Version</b>1.0.0</div>
    <strong>© 2014-2016 某某公司版权所有</strong>
  </footer>
</template>

<script>
export default {
  name: 'copyright'
};
</script>
