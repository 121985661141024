<template>
  <img :id="id" :href="image" :src="image" @load="imageLoadSuccess" @error="imageLoadError" class="image-view load"/>
</template>

<script>
import "@/assets/plugins/magnify/jquery.magnify.css"
import "@/assets/plugins/magnify/jquery.magnify.js"
export default {
  name: 'image-view',
  props: {
    src: String
  },
  data() {
    return {
      id: 'image-view' +
          new Date().getTime() +
          Math.floor(Math.random() * 100000),
      image: this.src
    }
  },
  watch: {
    src(val) {
      this.image = val
    }
  },

  mounted() {
    $("#"+this.id).magnify({
      headToolbar: [
        'close'
      ],
      footToolbar: [
        'zoomIn',
        'zoomOut',
        'fullscreen',
        'rotateRight'
      ],
      title: false
    });
  },

  methods: {
    //图片加载成功
    imageLoadSuccess(){
      $("#"+this.id).removeClass("load");
    },
    //图片加载失败
    imageLoadError() {
      this.image = require('../../assets/images/view/img-error.png')
    }
  }
}
</script>
<style>
.image-view {
  background-size: 100% 100%;
  cursor: pointer;
}

.image-view.load{
  background: url('../../assets/images/view/loading.gif') no-repeat;
}
</style>
