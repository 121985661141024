<template>
  <div v-html="data.description"></div>
</template>

<script>
export default {
  name: "goods",
  data: function () {
    return {
      id: this.$route.query.id,
      data:{
        goodsItems:[],
      },
    }
  },
  mounted() {
    this.bindData();
  },

  methods: {
    bindData() {
      this.$Http("/goods-detail", {goodsId: this.id}, "GET").then(result => {
        this.data = result.data.data;
      });
    }
  }
}
</script>

<style scoped>

</style>