<template>
  <div class="breadcrumbview">
    <div style="font-size: 16px; color: #999">
      当前位置：
      <span
        @click="linkTo(item.route)"
        v-for="(item, index) in breadcrumblist"
        v-bind:key="index"
        style="cursor: pointer"
        :style="index == breadcrumblist.length - 1 ? 'color:#3E6FF5' : ''"
        >{{ index != 0 ? ' / ' : '' }}{{ item.name }}</span
      >
    </div>
    <div style="position: fixed; right: 30px; top: 60px; z-index: 1029"></div>
  </div>
</template>

<script>
export default {
  name: 'breadcrumb-view',
  props: {
    breadcrumblist: []
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {
    linkTo(route) {
      if (route) {
        this.$router.push(route);
      }
    }
  }
};
</script>
<style scoped>
.breadcrumbview {
  display: flex;
  align-items: center;
  height: 50px;
  padding: 0 20px;
  background: #f7faff;
  box-shadow: 0px 0px 6px rgba(15, 86, 179, 0.1);
  border-bottom: #e6e6e6 solid 1px;
}
</style>
