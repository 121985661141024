<template>
  <div class="attachment-box">
    <span class="attachment-icon" :class="fileIcon == 'fa fa-image-o' ? 'has-img' : ''">
      <i :class="fileIcon" v-if="fileIcon != 'fa fa-image-o'"></i>
      <image-view :src="path" v-else />
    </span>
    <div class="attachment-info">
      <div role="button" class="attachment-name" style=' text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;'><i class="fa fa-paperclip"></i>{{ fileName }}</div>
      <span class="attachment-size">
        <span>{{ fileSize(size) }}</span>
        <a class="pull-right" @click="fileDownload" style="margin-top: -6px; font-size: 16px; color: #666"><i class="fa fa-cloud-download"></i></a>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'file-view',
  props: {
    fileName: String,
    suffix: String, //后缀
    size: [String, Number],
    path: String
  },
  data() {
    return {
      fileIcon: 'fa fa-file-o'
    };
  },
  mounted: function () {
    this.bindData();
  },
  watch: {
    suffix(val) {
      this.bindData();
    }
  },
  //过滤器
  filters: {},
  methods: {
    bindData: function () {
      let type = {
        '.jpg': 'fa fa-image-o',
        '.jpeg': 'fa fa-image-o',
        '.png': 'fa fa-image-o',
        '.bmp': 'fa fa-image-o',
        '.gif': 'fa fa-image-o',

        '.pdf': 'fa fa-file-pdf-o',
        '.txt': 'fa fa-file-txt-o',
        '.docx': 'fa fa-file-word-o',
        '.xlsx': 'fa fa-file-excel-o',
        '.mp3': 'fa fa-file-audio-o',
        '.doc': 'fa fa-file-word-o',
        '.xls': 'fa fa-file-excel-o',

        '.mp4': 'fa fa-file-movie-o',
        '.avi': 'fa fa-file-movie-o',
        '.rm': 'fa fa-file-movie-o',
        '.rmvb': 'fa fa-file-movie-o',
        '.flv': 'fa fa-file-movie-o',
        '.mpg': 'fa fa-file-movie-o',
        '.wma': 'fa fa-file-movie-o',
        '.3gp': 'fa fa-file-movie-o'
      };
      if (this.suffix !== null) {
        this.fileIcon = type[this.suffix] || 'fa fa-file-o';
      }
      //根据后缀能进行打开
    },
    fileSize: function (val) {
      if (val === 0) {
        return '0 B';
      }
      let k = 1024;
      let sizeUnit = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
      let i = Math.floor(Math.log(val) / Math.log(k));
      return Math.round((val / Math.pow(k, i).toPrecision(3)) * 100) / 100 + ' ' + sizeUnit[i];
    },
    //下载功能
    fileDownload() {
      let link = document.createElement('a');
      link.download = this.fileName;
      link.style.display = 'none';
      link.target = '_blank';
      link.href = this.path;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};
</script>
