<template>
  <div>
    <div class="tabGroup">
      <div v-for="(item, index) in tabList" :key="index" :class="{active: index == activeIndex}"
           class="tab-item"
           @click="clickEvent(index)">
        {{ item }}
      </div>
    </div>
    <div style="clear: both"></div>
  </div>
</template>

<script>
export default {
  name: "tab-group",
  props: {
    tabList: {
      type:Array,
      default:[]
    },
    onChange:{
      type:Function,
      default:()=>{}
    }
  },
  data() {
    return {
      activeIndex: 0
    };
  },
  methods: {
    clickEvent(index){
      this.activeIndex=index;
      this.onChange&&this.onChange(index);
    }
  }
};
</script>

<style scoped>
.tabGroup {
  height: 30px;
  background: #ffffff;
  border-radius: 15px;
  box-shadow: inset 0 1px 2px rgba(97, 97, 97, 0.2);
  display: flex;
  align-items: center;
  text-align: center;
  color: #999999;
  padding: 4px;
  float: left;
}

.tab-item {
  border: 1px solid transparent;
  border-radius: 12px;
  padding: 0 10px;
  line-height: 22px;
  cursor: pointer;
  min-width: 52px;
}

.tab-item.active {
  background: #3e6ff5 !important;
  color: #ffffff !important;
  border-radius: 12px;
  box-shadow: 0 1px 2px rgba(97, 97, 97, 0.2);
}

.tab-item:hover {
  box-shadow: 0 1px 2px rgba(97, 97, 97, 0.2);
}
</style>
