import menu from './menu'
import store from './vuex/store';
import StorageUtils from './utils/StorageUtils';
import router from '@/router'

export default class MenuUtils {

  /**
   * 跳转页面并刷新菜单
   * @param path
   * @param query
   * @param blank
   */
  static routerQueryTo(path, query = {}, blank = false) {
    if (blank) {
      const target = router.resolve({path: path, query: query});
      window.open(target.href, '_blank');
    } else {
      router.push({path: path, query: query});
      MenuUtils.menuRefresh(path);
    }
  }

  /**
   * 跳转页面并刷新菜单
   * @param path
   * @param params
   * @param blank
   */
  static routerParamsTo(path, params = {}, blank = false) {
    if (blank) {
      const target = router.resolve({path: path, params: params});
      window.open(target.href, '_blank')
    } else {
      router.push({path: path, params: params});
      MenuUtils.menuRefresh(path);
    }
  }


  /**
   * 根据路由刷新重置菜单选中状态
   * @param path
   */
  static menuRefresh(path) {
    for (let i = 0, len = menu.length; i < len; i++) {
      let item = menu[i];
      if (item.route == path) {
        let headerMenu = MenuUtils.menuParentGetById(item.id);
        store.storeSet("currentAdminMenu", headerMenu.id);
        StorageUtils.sessionStorageSet("currentAdminMenu", headerMenu.id);
        setTimeout(() => {
          MenuUtils.menuSelectByPath(path);
        }, 200);
        break;
      }
    }
  }


  /**
   * 根据路由获取一级菜单
   */
  static menuParentGetByPath(path) {
    for (let i = 0, len = menu.length; i < len; i++) {
      let item = menu[i];
      if (item.route == path) {
        if (item.parentId == null || item.parentId == '') {
          return item;
        } else {
          return MenuUtils.menuParentGetById(item.parentId);
        }
        break;
      }
    }
  }


  /**
   * 根据id获取一级菜单
   */
  static menuParentGetById(id) {
    for (let i = 0, len = menu.length; i < len; i++) {
      let item = menu[i];
      if (item.id == id) {
        if (item.parentId == null || item.parentId == '') {
          return item;
        } else {
          return MenuUtils.menuParentGetById(item.parentId);
        }
        break;
      }
    }
  }

  /**
   * 根据当前路由选中菜单
   * @param path
   */
  static menuSelectByPath(path) {
    for (let i = 0, len = menu.length; i < len; i++) {
      let item = menu[i];
      if (item.route == path) {
        MenuUtils.menuSelectById(item.id);
        break;
      }
    }
  }

  /**
   * 根据id选中菜单 此方法谨慎使用
   * 严禁使用魔法值 例如：MenuUtils.menuSelectById(1)
   * menu文件一修改  上面这个写法就会全部出错
   * 正确用法：MenuUtils.menuSelectById(menu.id) 或使用 MenuUtils.menuSelectByPath('xx/xx')
   * @param id
   */
  static menuSelectById(id) {
    $.zhoujl.menuActive(id)
  }

  static openPage(location) {
    router.push(location);
    let selectOpenPage = store.storeGet("selectOpenPage");
    if (selectOpenPage == null) {
      selectOpenPage = {};
    }
    if (selectOpenPage.currentPage == null || selectOpenPage.currentPage.length == 0) {
      selectOpenPage.currentPage = [location];
    } else {
      selectOpenPage.currentPage.push(location);
    }
  }

  static backPage() {
    router.go(-1);
  }
}
