/**
 * 本地存储
 */
export default class StorageUtils {
  /**
   * @param key
   * @param params 数据
   * 参数存储设置
   */
  static paramsSet(key, params) {
    StorageUtils.sessionStorageSet(key, JSON.stringify(params))
  }

  /**
   * 获取存储参数
   * @param key
   * @returns {*}
   */
  static paramsGet(key) {
    let data = StorageUtils.sessionStorageGet(key)
    if (data != null) {
      return JSON.parse(data)
    } else {
      return null
    }
  }

  /**
   * 设置临时存储
   * @param key
   * @param value
   */
  static sessionStorageSet(key, value) {
    sessionStorage.setItem(key, value)
  }

  /**
   * 获取临时存储
   * @param key
   */
  static sessionStorageGet(key) {
    return sessionStorage.getItem(key)
  }

  /**
   * 设置长期存储
   * @param key
   * @param value
   */
  static localStorageSet(key, value) {
    localStorage.setItem(key, value)
  }

  /**
   * 获取长期存储
   * @param key
   */
  static localStorageGet(key) {
    return localStorage.getItem(key)
  }
}
