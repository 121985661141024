<template>
  <div :id="id"></div>
</template>

<script>
import * as echarts from 'echarts'

export default {
  name: 'echart-view',
  props: ['option'],
  data() {
    return {
      id:
        'echart-view' +
        new Date().getTime() +
        Math.floor(Math.random() * 100000),
      chartGraph: null
    }
  },
  watch: {
    option: {
      handler(newValue, oldValue) {
        this.drawChartGraph(newValue)
      },
      deep: true
    }
  },
  beforeDestroy() {
    if (this.chartGraph) {
      this.chartGraph.clear()
    }
    window.removeEventListener('resize', this.resize)
  },
  mounted() {
    this.drawChartGraph(this.option)
    window.addEventListener('resize', this.resize)
  },
  methods: {
    drawChartGraph(data) {
      if (this.chartGraph) {
        this.chartGraph.clear()
      }
      this.chartGraph = echarts.init(document.getElementById(this.id))
      this.chartGraph.setOption(data)
    },
    resize() {
      if (this.chartGraph) {
        this.chartGraph.resize()
      }
    }
  }
}
</script>
