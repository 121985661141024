<template>
  <div style="height: 100%;">
    <router-view/>
  </div>
</template>
<script>
export default {
  name: 'app'
}
</script>
<style>
html,body,#app{
  height: 100% !important;
}
</style>
