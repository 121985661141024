<template>
  <div class="modal" :id="id" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click="hide"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <h4 class="modal-title">
            {{modalTitle }}
          </h4>
        </div>
        <div class="modal-body">
          <slot></slot>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-default"
            data-dismiss="modal"
            @click="hide"
          >
            {{closeName }}
          </button>
          <button type="button" class="btn btn-primary" @click="submit">
            {{confirmName }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StringUtils from '../../utils/StringUtils'
export default {
  data: function () {
    return {
      id:
        'modal-view' + new Date().getTime() + Math.floor(Math.random() * 100000)
    }
  },
  name: 'modal-view',
  props: {
    submitEvent: null,
    hideEvent: null,
    size: String,
    modalTitle: {
      type:String,
      default:'模态框'
    },
    closeName: {
      type:String,
      default:'取消'
    },
    confirmName: {
      type:String,
      default:'确认'
    }
  },
  install: function (Vue) {
    Vue.component('modal-view', this)
  },

  mounted: function () {
    this.init()
  },

  methods: {
    init: function () {
      if (!StringUtils.isNullOrEmpty(this.size)) {
        switch (this.size) {
          case 'full':
            $('#' + this.id)
              .find('.modal-dialog')
              .removeClass()
              .addClass('modal-dialog modal-full')
            break
          case 'large':
            $('#' + this.id)
              .find('.modal-dialog')
              .removeClass()
              .addClass('modal-dialog  modal-lg')
            break
          case 'small':
            $('#' + this.id)
              .find('.modal-dialog')
              .removeClass()
              .addClass('modal-dialog  modal-sm')
            break
          default:
            $('#' + this.id)
              .find('.modal-dialog')
              .removeClass()
              .addClass('modal-dialog')
            break
        }
      }
    },

    show: function () {
      $('#' + this.id).modal({
        backdrop: 'static'
      })
    },
    hide: function () {
      if (this.hideEvent != null) {
        this.hideEvent()
      }
      $('#' + this.id).modal('hide')
    },
    submit: function () {
      if (this.submitEvent != null) {
        this.submitEvent()
      }
    }
  }
}
</script>
