<template>
  <div
    class="input-group input-group-sm"
    style="width: 200px; box-shadow: 0 2px 4px 0 rgba(51, 51, 51, 0.01)"
  >
    <div class="input-group-btn">
      <a
        class="btn btn-default btn-sm disabled"
        style="box-shadow: none"
        v-if="pagerData.pageNumber <= 1"
        >首页</a
      >
      <a
        class="btn btn-default btn-sm"
        style="box-shadow: none"
        v-else
        v-on:click="changePage(1)"
        >首页</a
      >
      <a
        class="btn btn-default btn-sm disabled"
        style="box-shadow: none"
        v-if="pagerData.pageNumber <= 1"
        >上一页</a
      >
      <a
        class="btn btn-default btn-sm"
        style="box-shadow: none"
        v-else
        v-on:click="changePage(pagerData.pageNumber - 1)"
        >上一页</a
      >
    </div>
    <input
      class="form-control"
      style="width: 60px; text-align: center"
      v-on:blur="blurEvent"
      v-on:focus="focusEvent"
      v-on:keyup="keyUpEvent"
      onkeyup='this.value = this.value.replace(/\D/gi, "")'
      type="text"
      placeholder="页数"
      :value="pagerData.pageNumber + '/' + pagerData.pageTotal"
    />
    <div class="input-group-btn" style="margin-left: -1px">
      <a
        class="btn btn-default btn-sm disabled"
        style="border-radius: 0; margin-left: -1px; box-shadow: none"
        v-if="pagerData.pageNumber >= pagerData.pageTotal"
        >下一页</a
      >
      <a
        class="btn btn-default btn-sm"
        style="border-radius: 0; margin-left: -1px; box-shadow: none"
        v-else
        v-on:click="changePage(pagerData.pageNumber + 1)"
        >下一页</a
      >
      <a
        class="btn btn-default btn-sm disabled"
        style="border-radius: 0; box-shadow: none"
        v-if="pagerData.pageNumber >= pagerData.pageTotal"
        >末页</a
      >
      <a
        class="btn btn-default btn-sm"
        style="border-radius: 0; box-shadow: none"
        v-else
        v-on:click="changePage(pagerData.pageTotal)"
        >末页</a
      >
    </div>
    <select
      class="form-control input-sm"
      style="width: 100px; margin-left: -1px"
      v-on:change="changePageSize"
      autocomplete="off"
    >
      <option selected="selected" value="10" v-if="pagerData.pageSize == 10">
        显示10条
      </option>
      <option value="10" v-else>显示10条</option>
      <option selected="selected" value="15" v-if="pagerData.pageSize == 15">
        显示15条
      </option>
      <option value="15" v-else>显示15条</option>
      <option selected="selected" value="20" v-if="pagerData.pageSize == 20">
        显示20条
      </option>
      <option value="20" v-else>显示20条</option>
      <option selected="selected" value="25" v-if="pagerData.pageSize == 25">
        显示25条
      </option>
      <option value="25" v-else>显示25条</option>
    </select>
  </div>
</template>
<script>
export default {
  data: function () {
    return {}
  },
  props: ['pagerData', 'onchange'],
  name: 'pager-view',
  //生命进程
  mounted: function () {
    this.init()
  },
  methods: {
    init: function () {
      this.focus = false
    },
    focusEvent: function (e) {
      if (this.focus == false) {
        this.focus = true
        $(e.target).val('')
      }
    },
    blurEvent: function (e) {
      if (this.focus == true) {
        this.focus = false
        let text = this.pagerData.pageNumber + '/' + this.pagerData.pageTotal
        $(e.target).val(text)
      }
    },
    keyUpEvent: function (e) {
      let text = $(e.target).val()
      if (e.keyCode == 13) {
        this.changePage($(e.target).val())
      }
    },
    changePage: function (pageIndex) {
      if (this.onchange != null) {
        if (pageIndex >= this.pagerData.pageTotal) {
          pageIndex = this.pagerData.pageTotal
        }
        let pageSize = this.pagerData.pageSize
        let pageTotal = this.pagerData.pageTotal
        this.onchange(pageSize, pageIndex, pageTotal)
      }
    },
    changePageSize: function (e) {
      if (this.onchange != null) {
        let pageSize = $(e.target).val()
        let pageIndex = this.pagerData.pageNumber
        let pageTotal = Math.ceil(this.pagerData.pageItemCount / pageSize)
        if (
          this.pagerData.pageNumber * pageSize >=
          this.pagerData.pageItemCount
        ) {
          pageIndex = pageTotal
        }
        if (pageIndex < 1) {
          pageIndex = 1
        }
        this.onchange(pageSize, pageIndex)
      }
    }
  }
}
</script>
