<template>
  <input  :id="id" :value="modelValue" v-on:input='inputEvent' v-on:propertychange='inputEvent'
          data-toggle='tooltip' data-placement='bottom' data-trigger='manual' autocomplete='off' />
</template>

<script>
import moment from 'moment'
import '../../assets/plugins/datetimepicker/jquery.datetimepicker'
import '../../assets/plugins/datetimepicker/jquery.datetimepicker.css'
import StringUtils from '../../utils/StringUtils'
import DateTimeUtils from "@/utils/DateTimeUtils";
export default {
  data: function () {
    return {
      id: 'date-time-view' +
        new Date().getTime() +
        Math.floor(Math.random() * 100000),
      isEmpty:false,
      //由于不同组件 格式规范会不一样 故增加formatObject 对外永远都是大写的YYYY MM DD HH mm ss 这样一个格式
      formatObject:{
        "YYYY-MM-DD":"Y-m-d",
        "YYYY-MM-DD HH:mm":"Y-m-d H:i",
        "YYYY-MM-DD HH:mm:ss":"Y-m-d H:i:00",
        "YYYY/MM/DD":"Y/m/d",
        "YYYY/MM/DD HH:mm":"Y/m/d H:i",
        "YYYY/MM/DD HH:mm:ss":"Y/m/d H:i:00"
      },
    }
  },
  name: 'date-time-view',
  props: {
    verify: {
      type: Boolean,
      default: false
    },
    format: {
      type: String,
      default: 'YYYY-MM-DD'
    },
    step:{
      type: Number,
      default:30,
    },
    yearStart:{
      type: Number,
      default:1950,
    },
    yearEnd:{
      type: Number,
      default:2050,
    },
    timePicker: {
      type: Boolean,
      default: false
    },
    nonEmpty: {
      type: Boolean,
      default: false
    },
    tip: String,
    modelValue: String,
  },
  install: function (Vue) {
    Vue.component('date-time-view', this)
  },

  mounted: function () {
    this.init()
  },

  methods: {
    init: function () {
      if(StringUtils.isNullOrEmpty(this.modelValue)){
        this.$emit('update:modelValue', "");
      }else{
        let text=moment(this.modelValue).format(this.format|| 'YYYY-MM-DD');
        this.$emit('update:modelValue', text);
      }
      $('#'+this.id).datetimepicker({
        lang:'ch',
        timepicker:this.timePicker,
        step:this.step,
        format:this.formatObject[this.format||'YYYY-MM-DD'],
        dayOfWeekStart:1,
        yearStart:this.yearStart,
        yearEnd:this.yearEnd,
        onChangeDateTime:(value)=>{
          if(this.isEmpty){
            this.$emit('update:modelValue', "");
            this.isEmpty=false;
            if (this.verify) {
              this.verifyValue("")
            }
          }else{
            let text=moment(value).format(this.format|| 'YYYY-MM-DD');
            this.$emit('update:modelValue', text);
            if (this.verify) {
              this.verifyValue(value)
            }
          }
        },
      });
    },

    inputEvent(e) {
      let text =$(e.target).val();
      if (this.verify) {
        this.verifyValue(text)
      }
      this.isEmpty=StringUtils.isNullOrEmpty(text);
    },

    verifyValue(value) {
      let errorMessage = null
      if (StringUtils.isNullOrEmpty(value) && this.nonEmpty) {
        errorMessage = '必填'
      }
      if (!StringUtils.isNullOrEmpty(errorMessage)) {
        $('#' + this.id).attr('title', this.tip||errorMessage)
        $('#' + this.id).attr('data-original-title', this.tip||errorMessage);
        $('#' + this.id).css('color', '#da5f4d').css('border-color', '#da5f4d')
        $('#' + this.id).tooltip('show')
        //由于 daterangepicker 和 tooltip会引发冲突故添加了下面这段代码
        if($('.daterangepicker.fade.bottom.in')){
          $('.daterangepicker.fade.bottom.in').removeClass('daterangepicker').addClass('tooltip').addClass(this.id)
              .html('<div class="tooltip-arrow" style="left: 50%;"></div><div class="tooltip-inner">' + this.tip||errorMessage + '</div>')
        }
        return false;
      } else {
        $('#' + this.id).tooltip('destroy')
        $('#' + this.id).css('color', '#4d4d4d').css('border-color', '#CED4DA')
        $('.' + this.id).remove();
        return true;
      }
    }
  }
}
</script>
