<template>
  <section>
    <div class="content">
      <div class="row">
        <div class="col-md-3 col-sm-6 col-xs-12">
          <div class="info-box"><span class="info-box-icon bg-aqua"><i class="fa fa-hand-pointer-o"></i></span>
            <div class="info-box-content">
              <span class="info-box-text">访问量总数（PV）</span>
              <span class="info-box-number">{{getTotalCount(0)}}</span>
            </div>
          </div>
        </div>

        <div class="col-md-3 col-sm-6 col-xs-12">
          <div class="info-box"><span class="info-box-icon bg-red"><i class="fa fa-desktop"></i></span>
            <div class="info-box-content">
              <span class="info-box-text">独立IP总数（IP）</span>
              <span class="info-box-number">{{getTotalCount(1)}}</span>
            </div>
          </div>
        </div>

        <div class="clearfix visible-sm-block"></div>
        <div class="col-md-3 col-sm-6 col-xs-12">
          <div class="info-box"><span class="info-box-icon bg-green"><i class="fa fa-mobile"></i></span>
            <div class="info-box-content">
              <span class="info-box-text">独立访客总数（UV）</span>
              <span class="info-box-number">{{getTotalCount(2)}}</span>
            </div>
          </div>
        </div>

        <div class="col-md-3 col-sm-6 col-xs-12">
          <div class="info-box"><span class="info-box-icon bg-yellow"><i class="fa  fa-shopping-cart"></i></span>
            <div class="info-box-content">
              <span class="info-box-text">订单总数</span>
              <span class="info-box-number">{{getTotalCount(3)}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12" id="search_container">
          <div class="box  box-primary">
            <div class="box-header with-border"><h3 class="box-title">经营分析</h3>
              <div class="pull-right" style="display: flex;align-items: center"><span>请选择年月</span>
                <div>
                  <select v-model="year">
                    <option :value="item" v-for="item in yearList">{{item}}</option>
                  </select>
                </div>
                年
                <div>
                  <select v-model="month" id="month_select">
                    <option :value="item" v-for="item in 12">{{item}}</option>
                  </select>
                </div>
                月
              </div>
            </div>
            <div class="box-body">
              <div class="row">
                <div class="col-md-8">
                  <div class="chart">
                    <echart-view :option="option" style="height: 300px;width: 100%"></echart-view>
                  </div>
                </div>
                <div class="col-md-4"><p class="text-center"><strong>本月统计</strong></p>
                  <div class="progress-group"><span class="progress-text">本月访问量总数</span> <span
                      class="progress-number">{{ data.totalMonthBrowseCount }}</span>
                    <div class="progress sm">
                      <div class="progress-bar progress-bar-aqua" style="width: 100%;"></div>
                    </div>
                  </div>
                  <div class="progress-group"><span class="progress-text">本月独立IP总数</span> <span
                      class="progress-number">{{ data.totalMonthIpCount }}</span>
                    <div class="progress sm">
                      <div class="progress-bar progress-bar-red" style="width: 100%;"></div>
                    </div>
                  </div>
                  <div class="progress-group"><span class="progress-text">本月独立访客总数</span> <span
                      class="progress-number">{{ data.totalMonthDeviceCount }}</span>
                    <div class="progress sm">
                      <div class="progress-bar progress-bar-green" style="width: 100%;"></div>
                    </div>
                  </div>
                  <div class="progress-group"><span class="progress-text">本月订单总数</span> <span
                      class="progress-number">{{ data.totalMonthOrderCount }}</span>
                    <div class="progress sm">
                      <div class="progress-bar progress-bar-yellow" style="width: 100%;"></div>
                    </div>
                  </div>
                  <div class="progress-group"><span class="progress-text">本月订单总金额</span> <span
                      class="progress-number">￥{{ data.totalMonthOrderMoney }}</span>
                    <div class="progress sm">
                      <div class="progress-bar progress-bar-primary" style="width: 100%;"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="box-footer">
              <div class="row">
                <div class="col-sm-3 col-xs-6">
                  <div class="description-block border-right">
                    <h5 class="description-header">{{data.avgDayOrderCount}}</h5>
                    <span class="description-text">本月日均订单数</span></div>
                </div>
                <div class="col-sm-3 col-xs-6">
                  <div class="description-block border-right">
                    <h5 class="description-header">￥{{data.avgOrderMoney}}</h5>
                    <span class="description-text">本月每单平均金额</span></div>
                </div>
                <div class="col-sm-3 col-xs-6">
                  <div class="description-block border-right">
                    <h5 class="description-header">￥{{data.maxOrderMoney}}</h5>
                    <span class="description-text">本月最高单笔消费</span></div>
                </div>
                <div class="col-sm-3 col-xs-6">
                  <div class="description-block">
                    <h5 class="description-header">{{data.avgBuyMoney}}</h5>
                    <span class="description-text">本月每个用户潜在消费金额</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>

import {throttle} from "@/utils/LimitUtils";

export default {
  watch:{
    year:function(){
      this.searchEvent();
    },
    month:function(){
      this.searchEvent();
    },
  },
  data: function () {
    return {
      year: new Date().getFullYear(),
      month: new Date().getMonth()+1,
      data: {},
      yearList:[],
      option:{},
      totalData:[],
    }
  },

  mounted: function () {
    this.init()
    this.bindData()
  },

  methods: {
    init: function () {
      let year=new Date().getFullYear()+1;
      for(let i=2020;i<year;i++){
        this.yearList.push(i);
      }
    },
    bindData: function () {
      this.$Http("/home-total", {}, "GET").then(result => {
        this.totalData = result.data.data;
      });
      this.searchEvent();
    },
    searchEvent(){
      throttle(() => {
        this.$Http("/home-analyse", {year: this.year, month: this.month}, "POST").then(result => {
          this.data = result.data.data.shopAnalyse;
          let dataList = result.data.data.dataList;
          var dateArray = new Array();
          var orderCount = new Array();
          var orderMoney = new Array();
          var discountPrice = new Array();
          $.each(dataList, (index, item) => {
            dateArray.push(item.monthDay);
            orderCount.push(item.totalOrderCount);
            orderMoney.push(item.totalOrderMoney);
            discountPrice.push(item.totalDiscountPrice);
          });
          this.option = {
            grid: {
              left: '3%',
              right: '4%',
              bottom: '3%',
              containLabel: true
            },
            xAxis: [
              {
                type: 'category',
                data: dateArray,
                axisPointer: {
                  type: 'shadow'
                }
              }
            ],
            yAxis: [
              {
                type: 'value',
                name: '销售额',
                min: 0,
                max: 250,
                interval: 50,
                axisLabel: {
                  formatter: '{value} 元'
                }
              },
              {
                type: 'value',
                name: '订单数',
                min: 0,
                max: 25,
                interval: 5,
                axisLabel: {
                  formatter: '{value} 单'
                }
              }
            ],
            series: [
              {
                name: '销售额',
                type: 'bar',
                tooltip: {
                  valueFormatter: function (value) {
                    return value + ' 元';
                  }
                },
                data: orderMoney
              },
              {
                name: '订单数',
                type: 'bar',
                tooltip: {
                  valueFormatter: function (value) {
                    return value + ' 单';
                  }
                },
                data: orderCount
              },
            ]
          };
        });
      });
    },

    getTotalCount(key){
      for(let i=0,len=this.totalData.length;i<len;i++){
        let item=this.totalData[i];
        if(item.key==key){
          return item.value;
        }
      }
      return 0;
    }
  }
}
</script>
