<template>
  <div>
    <!-- 右侧展开菜单 -->
    <aside class="control-sidebar control-sidebar-dark" id="control-sidebar">
      <!-- Create the tabs -->
      <ul class="nav nav-tabs nav-justified control-sidebar-tabs">
        <li class="active">
          <a href="#control-sidebar-tab1" data-toggle="tab"><i class="fa fa-wrench"></i></a>
        </li>
        <li>
          <a href="#control-sidebar-tab2" data-toggle="tab"><i class="fa fa-home"></i></a>
        </li>
        <li>
          <a href="#control-sidebar-tab3" data-toggle="tab"><i class="fa fa-gears"></i></a>
        </li>
      </ul>
      <!-- Tab panes -->
      <div class="tab-content">
        <div class="tab-pane active" id="control-sidebar-tab1">tab1</div>

        <div class="tab-pane" id="control-sidebar-tab2">tab2</div>

        <div class="tab-pane" id="control-sidebar-tab3">tab3</div>
      </div>
    </aside>
    <!-- 右侧展开菜单展开面板 -->
    <div class="control-sidebar-bg" style="height: auto; position: fixed"></div>
  </div>
</template>

<script>
export default {
  name: 'control-sidebar'
};
</script>
