<template>
  <div class="summernote" :id="id"></div>
</template>

<script>
import Vue from 'vue'
import 'summernote'
import 'summernote/dist/lang/summernote-zh-CN'
import 'summernote/dist/summernote.css'
import '@/assets/plugins/summernote/summernote-style.css'
export default {
  name: 'rich-input-view',

  data: function () {
    return {
      id: 'rich-input-view' +
          new Date().getTime() +
          Math.floor(Math.random() * 100000),
      init:false,
      input:false,
    }
  },

  props:{
    modelValue: {
      type:String,
      default:''
    },
  },

  watch:{
    modelValue:function(){
      if(!this.input){
        $("#" + this.id).summernote("code",this.modelValue);
      }
    }
  },

  mounted: function () {
    this.initView()
  },

  methods: {
    initView: function () {
      let that=this;
      $('#' + this.id).summernote({
        lang: 'zh-CN',
        placeholder: '请输入内容',
        height: 240,
        toolbar: [
          // ['edit',['undo','redo']],//编辑
          ['style', ['style']],
          ['fontsize', ['fontsize']],
          ['font', ['bold', 'underline', 'clear']],
          ['fontname', ['fontname']],
          ['color', ['color']],
          ['para', ['ul', 'ol', 'paragraph']],
          ['table', ['table']], //表单
          ['insert', ['link', 'picture', 'hr']], //插入链接，图片，下划线
          ['view', ['fullscreen', 'codeview']] //全屏，代码视图
        ],
        fontSizes: [
          '8',
          '9',
          '10',
          '11',
          '12',
          '14',
          '18',
          '24',
          '36',
          '48',
          '64'
        ], //字体大小配置
        fontNames: [
          '宋体',
          '微软雅黑',
          '楷体',
          '黑体',
          '隶书',
          'Arial',
          'Arial Black',
          'Comic Sans MS',
          'Courier New',
          'Helvetica Neue',
          'Helvetica',
          'Impact',
          'Lucida Grande',
          'Tahoma',
          'Times New Roman',
          'Verdana'
        ],
        callbacks: {
          onChange: function (ev) {
            that.inputEvent(ev);
          }
        }
      })
      $("#" + this.id).summernote("code",this.modelValue);
    },
    inputEvent() {
      let text= $("#" + this.id).summernote("code");
      if(this.init){
        this.input=true;
      }else{
        this.init=true;
      }
      this.$emit("update:modelValue", text);
    }
  }
}
</script>

<style scoped></style>
