<template>
  <select :id="id" data-toggle='tooltip' data-placement='bottom' data-trigger='manual'>
    <option value="">{{placeholder}}</option>
    <option
        :key="item.key"
        v-for="item in options"
        v-bind:value="item.key"
        :selected="modelValue === item.key"
    >
      {{ item.value }}
    </option>
  </select>
</template>

<script>
import '../../assets/plugins/select2/select2.js'
import '../../assets/plugins/select2/select2.css'
import StringUtils from '../../utils/StringUtils'
export default {
  name: 'select-view',
  props: {
    placeholder:{
      type: String,
      default: "请选择"
    },
    verify: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      default: []
    },
    nonEmpty: Boolean,
    modelValue: [String, Number]
  },

  data: function () {
    return {
      id:
          'select-view' +
          new Date().getTime() +
          Math.floor(Math.random() * 100000)
    }
  },

  mounted: function () {
    this.init()
  },
  watch: {
    modelValue(val) {
      $('#' + this.id)
          .val(val)
          .trigger('change')
    }
  },
  methods: {
    init: function () {
      $('#' + this.id).select2()
      $('#' + this.id).on('select2:select', e => {
        this.$emit('update:modelValue', e.target.value)
        if (this.verify) {
          this.verifyValue(e.target.value)
        }
      })
    },
    //验证输入是否正确
    verifyValue(value) {
      if (StringUtils.isNullOrEmpty(value) && this.nonEmpty) {
        $('#' + this.id).attr('title', '必选项')
        $('#' + this.id).tooltip('show')
        $('#select2-' + this.id+"-container").css('color', '#da5f4d');
        $('#select2-' + this.id+"-container").parent().css('border-color', '#da5f4d')
      } else {
        $('#' + this.id).tooltip('hide')
        $('#select2-' + this.id+"-container").css('color', '#4d4d4d');
        $('#select2-' + this.id+"-container").parent().css('border-color', '#CED4DA')
      }
    }
  }
}
</script>
