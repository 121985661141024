<template>
  <select :id="id" v-model="modelValue"  multiple='multiple' data-toggle='tooltip' data-placement='bottom' data-trigger='manual'>
    <option :key="item.key" v-for="item in options" v-bind:value="item.key">
      {{ item.value }}
    </option>
  </select>
</template>

<script>
import '../../assets/plugins/select2/select2.js'
import '../../assets/plugins/select2/select2.css'
export default {
  name: 'multiple-select-view',
  props: {
    options: {
      type: Array,
      default: []
    },
    verify: {
      type: Boolean,
      default: false
    },
    nonEmpty: Boolean,
    modelValue: Array,
  },

  data: function () {
    return {
      id:
        'multiple-select-view' +
        new Date().getTime() +
        Math.floor(Math.random() * 100000)
    }
  },

  mounted: function () {
    this.init()
  },

  watch: {
    modelValue(val) {
      $('#' + this.id)
        .val(val)
        .trigger('change')
    }
  },

  methods: {
    init: function () {
      $('#' + this.id).select2()
      $('#' + this.id).on('select2:select', e => {
        this.$emit('update:modelValue', $('#' + this.id).select2('val'))
        if(this.verify){
          this.verifyValue()
        }
      })
      $('#' + this.id).on('select2:unselect', e => {
        this.$emit('update:modelValue', $('#' + this.id).select2('val'))
        if(this.verify){
          this.verifyValue()
        }
      })
      if(this.options.length>20){
        throw new Error("选项较多的不推荐使用multiple-select-view，用户体验不好");
      }
    },
    //验证输入是否正确
    verifyValue() {
      if (this.modelValue.length==0&& this.nonEmpty) {
        $('#' + this.id).attr('title', '必选项')
        $('#' + this.id).tooltip('show')
        $('#select2-' + this.id + '-container').css('color', '#da5f4d')
        $('#select2-' + this.id + '-container').parent().css('border-color', '#da5f4d')
      } else {
        $('#' + this.id).tooltip('hide')
        $('#select2-' + this.id + '-container').removeAttr('style', '')
        $('#select2-' + this.id + '-container').parent().removeAttr('style', '')
      }
    }
  }
}
</script>
