<template>
  <div>
    <div :id="id">
      <slot></slot>
    </div>
    <div :id="waterId" style="display: none">
      <div
        class="mark"
        style="
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          z-index: 999;
          padding: 0 50px 0 50px;
        "
      ></div>
    </div>
    <iframe :id="iframeId" style="display: none"></iframe>
  </div>
</template>
<script>
import StringUtils from '../../utils/StringUtils'
export default {
  data: function () {
    return {
      id:
        'print-view' +
        new Date().getTime() +
        Math.floor(Math.random() * 100000),
      iframeId:
        'print-iframe' +
        new Date().getTime() +
        Math.floor(Math.random() * 100000),
      waterId:
        'print-water' +
        new Date().getTime() +
        Math.floor(Math.random() * 100000)
    }
  },
  name: 'print-view',

  props: {
    watermark: String
  },

  mounted: function () {
    this.init()
  },

  methods: {
    init: function () {
      if (!StringUtils.isNullOrEmpty(this.watermark)) {
        let mark = this.getWatermarkText()
        let page = Math.ceil($('#' + this.id).height() / 1242)
        let height = page * 841.89
        let count = page * 33
        $('#' + this.waterId)
          .children('.mark')
          .css('height', height)
          .css('width', $('#' + this.id).width())
        for (let i = 0; i < count; i++) {
          $('#' + this.waterId)
            .children('.mark')
            .append("<img src='" + mark + "' style='width:220px'/>")
        }
      }
    },

    //获得能够打印的水印文字
    getWatermarkText: function () {
      let canvas = document.createElement('canvas')
      let context = canvas.getContext('2d')
      context.rotate((-30 * Math.PI) / 180)
      context.width = 200
      context.height = 200
      context.font = '26px Microsoft JhengHei'
      context.fillStyle = 'rgba(17, 17, 17, 0.3)'
      context.textAlign = 'center'
      context.textBaseline = 'Middle'
      context.fillText(this.watermark, 140, 150)
      return canvas.toDataURL('image/png')
    },

    //获得能够打印的图片
    getPrintImage: function (src, width, height, callback) {
      let canvas = document.createElement('canvas')
      if (canvas.getContext) {
        canvas.width = width
        canvas.height = height
        let ctx = canvas.getContext('2d')
        let img = new Image()
        img.src = src
        img.onload = function () {
          ctx.drawImage(img, 0, 0, width, height)
          if (callback != null) {
            callback(canvas.toDataURL('image/png'))
          }
        }
      }
    },

    //打印方法
    print: function () {
      let iframe = document.getElementById(this.iframeId).contentWindow
      iframe.document.body.innerHTML =
        $('#' + this.waterId).html() +
        "<div style='padding: 30px'>" +
        $('#' + this.id).html() +
        '</div>'
      iframe.print()
    }
  }
}
</script>
